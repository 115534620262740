<template>
  <v-col sm="7" md="5" lg="3" class="px-0">
    <v-autocomplete
      item-value="item"
      item-text="key"
      :items="enumItems"
      :label="label"
      v-model="itemValue"
      auto-select-first
      deletable-chips
      small-chips
      multiple
      hide-no-data
      hide-selected
      allow-overflow
      min-height="32px"
      name="item"
      @input="handleInput"
    >
      <v-icon slot="append" title="removeFilter" @click="$emit('remove')">
        mdi-close
      </v-icon>
    </v-autocomplete>
  </v-col>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "TagAutoCompFilter",
  props: {
    tags: [],
    id: {
      type: String,
    },
    label: {
      type: String,
    },
    value: {},
    allowEmpty: {
      type: Boolean,
      default: false,
    },
  },
  data: function () {
    return {
      availableItems: this.channels,
      itemValue: this.value,
      closeOnContentClick: true,
      searchInput: null,
    };
  },
  watch: {
    value: {
      handler: function () {
        this.itemValue = this.value;
      },
      deep: true,
    },
  },
  methods: {
    handleInput: function () {
      this.$emit("input", this.itemValue);
    },
  },
  computed: {
    ...mapGetters("channels", ["channelList"]),
    ...mapGetters("tag", ["tagNames"]),
    enumItems: function () {
      let tags = this.tagNames;
      if (this.allowEmpty) {
        tags.unshift({
          key: "notSet",
          item: "notSet",
        });
      }
      return tags;
    },
  },
};
</script>
