<template>
  <v-dialog v-model="dialog" max-width="500px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        depressed
        rounded
        color="primary"
        style="float: right"
        class="mt-6 mx-1"
        v-bind="attrs"
        v-on="on"
        >Add source</v-btn
      >
    </template>
    <v-card>
      <v-form @submit.prevent="saveSource" ref="sourceForm">
        <v-row no-gutters class="my-0 py-0">
          <v-spacer></v-spacer
          ><v-card-actions>
            <v-btn icon @click="close">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-actions>
        </v-row>
        <v-card-title>
          <v-row class="mx-2"> Add source </v-row>
        </v-card-title>
        <v-card-text>
          <v-alert text color="red" type="error" v-if="errorMsg">
            {{ errorMsg }}
          </v-alert>
          <v-container>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="newItem.url"
                  required
                  :rules="[inputRequired, urlRule]"
                  label="Source URL"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-autocomplete
                  ref="autocomplete"
                  v-model="newItem.relatedTagsList"
                  chips
                  deletable-chips
                  small-chips
                  required
                  item-value="key"
                  item-text="label"
                  :rules="[tagsRequired, minTagCharsRule, maxTagCharsRule]"
                  :items="items"
                  label="Tags"
                  multiple
                  @click="onChangeTagItems"
                  v-on:keydown.enter="onChangeTagItems"
                  @clear="onRemoveTagItems"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" md="5">
                <v-text-field
                  v-model="newItem.domainRating"
                  :rules="[isFloatRule]"
                  label="Domain rating"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="5">
                <v-select
                  v-model="newItem.parsingGroup"
                  :items="parsingGroups"
                  item-value="value"
                  item-text="title"
                  :rules="[inputRequired]"
                  label="Parsing group"
                  required
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-select
                  v-model="newItem.isActive"
                  item-value="attribute"
                  item-text="label"
                  :items="boolItems"
                  :rules="[inputRequired]"
                  label="Enabled"
                  required
                ></v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey darken-1" text @click="close"> Cancel </v-btn>
          <v-btn color="primary" text @click="saveSource"> Save </v-btn>
          <v-btn color="primary" text @click="saveSourceGo"> Save & Go </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from "lodash";
import { mapGetters } from "vuex";
import { CREATE_SOURCE } from "@/store/modules/source/actions";
import { NEW_CREATED_SOURCE } from "@/utils/events";
import { EventBus } from "@/utils";
import { FETCH_PARSING_GROUPS_DICTIONARY } from "@/store/modules/parsingGroup/actions";

export default {
  name: "SourceAddDialog",
  data() {
    return {
      items: [],
      dialog: false,
      newItem: {
        name: null,
        relatedTagsList: [],
        url: null,
        domainRating: null,
        parsingGroup: 0,
        isActive: 1,
      },
      defaultItem: {
        name: null,
        relatedTagsList: [],
        url: null,
        domainRating: null,
        parsingGroup: 0,
        isActive: 1,
      },
      boolItems: [
        {
          attribute: 1,
          label: "Yes",
        },
        {
          attribute: 0,
          label: "No",
        },
      ],
      errorMsg: "",
      inputRequired: (v) => !!v || v === 0 || "Required field",
      tagsRequired: (v) => !!v.length || "Required field",
      minCharsRule: (v) => (v && v.length >= 3) || "Input too short!",
      maxCharsRule: (v) => (v && v.length <= 40) || "Input too long!",
      isFloatRule: (v) => !v || /^-?\d+(\.\d+)?$/.test(v) || "Digit required",
      minTagCharsRule: (v) => {
        const tooShortItems = v.filter(function (item) {
          return item.length < 2;
        });

        return tooShortItems.length === 0 || "Input too short!";
      },
      maxTagCharsRule: (v) => {
        const tooLongItems = v.filter(function (item) {
          return item.length > 40;
        });

        return tooLongItems.length === 0 || "Input too long!";
      },
      urlRule: (v) =>
        new RegExp(
          "^(https?:\\/\\/)?" +
            "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" +
            "((\\d{1,3}\\.){3}\\d{1,3}))" +
            "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*",
          "i"
        ).test(v) || "Incorrect URL",
      requiredRule: (v) => !!v || "Not enough characters to save the source",
    };
  },
  computed: {
    ...mapGetters("tag", ["tagNames"]),
    ...mapGetters("parsingGroup", ["parsingGroups"]),
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    tagNames() {
      this.items = _.merge([], this.tagNames);
    },
  },
  methods: {
    onChangeTagItems: function (v) {
      let val = v.target.value;
      let array = document.querySelectorAll('[id^="list-item"]');
      let highlightedMenuItem = [];
      let activeMenuItem = [];
      array.forEach((element) => {
        if (element.classList.contains("v-list-item--highlighted")) {
          if (!element.classList.contains("v-list-item--active")) {
            highlightedMenuItem.push(true);
          }
        } else if (element.classList.contains("v-list-item--active")) {
          activeMenuItem.push(true);
        }
      });
      if (
        val &&
        val !== "" &&
        this.items.indexOf(val) === -1 &&
        !highlightedMenuItem.length
      ) {
        this.items.push(val);
        setTimeout(
          function () {
            let array = document.querySelectorAll('[id^="list-item"]');
            if (array) {
              [...array].pop().click();
            }
          }.bind(this),
          100
        );
      }
    },
    onRemoveTagItems(v) {
      let val = v.target.value;
      const index = this.items.indexOf(val);
      if (val && val !== "" && index !== -1) {
        this.items.splice(index, 1);
      }
    },
    pushOrRemoveStates(v) {
      console.log(v);
    },
    close() {
      this.dialog = false;
      this.errorMsg = null;
      this.$refs.sourceForm.resetValidation();
      this.newItem = _.merge({}, this.defaultItem);
    },
    saveSourceGo() {
      let vm = this;
      return this.createSource(function () {
        vm.errorMsg = null;
        vm.$refs.sourceForm.resetValidation();
        vm.newItem = _.merge({}, vm.defaultItem);
        EventBus.$emit(NEW_CREATED_SOURCE);
      });
    },
    saveSource() {
      let vm = this;
      return this.createSource(function (response) {
        vm.close();
        let { id } = response.data;
        EventBus.$emit(NEW_CREATED_SOURCE, id);
      });
    },
    createSource(resolve) {
      let vm = this;
      if (!vm.$refs.sourceForm.validate()) {
        this.errorMsg = "Please check all fields!";
      } else {
        let { newItem } = this;
        this.$store
          .dispatch(`source/${CREATE_SOURCE}`, newItem)
          .then(resolve, function (error) {
            vm.errorMsg = error;
          })
          .catch(() => {
            this.errorMsg = "Something went wrong";
          });
      }
    },
    fetchParsingGroups: function () {
      this.$store.dispatch("parsingGroup/" + FETCH_PARSING_GROUPS_DICTIONARY);
    },
  },
  created() {
    this.items = _.merge([], this.tagNames);
    this.fetchParsingGroups();
  },
};
</script>
